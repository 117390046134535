import React from 'react'

const Profile = () => {
  return (
    <div>
      
      <p className='text-left border shadow py-3 px-2'>
        Indiviual Account
      </p>
    </div>
  )
}

export default Profile