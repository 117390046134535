import React from 'react'

const Document = () => {
  return (
    <div>
      <div className='max-w-7xl min-h-80 my-10 bg-white rounded-md m-auto'>
        <h3 className='my-4 text-gray-400 font-medium text-lg'>No Document</h3>

      </div>
    </div>
  )
}

export default Document